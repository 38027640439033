import { WeatherInfo } from "../api/weatherInfo.type";
import { amazonLinks } from "./amazon-links";
import { AddLink } from "./AddLink";
import { getBeaufortWindForce } from "../weather/utils";
import { isWithinInterval } from "date-fns";

interface Props {
  weatherInfo: WeatherInfo | undefined | null;
  distance: number | undefined;
  placeName: string | undefined;
}

export const Add = ({ weatherInfo, distance, placeName }: Props) => {
  if (!weatherInfo) {
    return null;
  }

  // TODO what's the best order?
  if (weatherInfo.rain) {
    return <AddLink link={amazonLinks.rain} />;
  }

  if (weatherInfo.snow) {
    return <AddLink link={amazonLinks.snow} />;
  }

  if (getBeaufortWindForce(weatherInfo.wind.speed) > 5) {
    return <AddLink link={amazonLinks.windy} />;
  }

  if (placeName?.includes("station")) {
    return <AddLink link={amazonLinks.cityLocation} />;
  }

  // // TODO this doesn't work yet
  // if (
  //   isWithinInterval(new Date(), {
  //     start: weatherInfo.sys.sunrise,
  //     end: weatherInfo.sys.sunset,
  //   })
  // ) {
  //   return <AddLink link={amazonLinks.dark} />;
  // }

  if (distance !== undefined && distance > 10000) {
    return <AddLink link={amazonLinks.longDistance} />;
  }

  if (weatherInfo.main.temp < 10) {
    return <AddLink link={amazonLinks.cold} />;
  }

  return null;
};
