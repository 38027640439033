import { useRef, useEffect } from 'react';
import type { EffectCallback, DependencyList } from 'react';

export function noop(): void {}

type PredicateFunction = () => unknown;

/**
 * Executes a side effect exactly once but only after some condition has been met.
 * If used without conditions, the effect will be executed exactly once.
 * A condition is only considered met if `condition` returns a truthy value.
 *
 * There are some side effects, like sending tracking events, that depend on async data
 * like the current account, sites, etc. If we used `useEffect` to send these events, we'd
 * specify these async data as dependencies. However, if dependencies change, e.g. a variable
 * is populated with asynchronously retrieved data, it will run the effect again, sending the
 * tracking event again, making your product manager (or anyone looking at metrics) sad.
 *
 * As a workaround, we could do `useEffect(myEffect, [])`, which means the effect will only run
 * once. But then how can you make sure that by the time the effect runs, your dependencies are
 * ready? You can't.
 *
 * To solve this, you need something that takes care of running the effect once, but only after
 * the dependencies are ready to be used. Since the definition of 'ready' can vary by use case, you
 * can declare a function that will return whether or not the effect is ready to be run. Every time
 * the dependencies change and the effect hasn't been run yet, `useEffectOnceWithDependencies` will
 * run your condition function, which, if returned a truthy value, will cause the effect to be called
 * exactly once and then never again.
 *
 * Example:
 * useEffectOnceWithDependencies(myEffect, [foo], () => foo.length === 3);
 * ^ This would execute `myEffect` once (and only once) if `foo` has length of 3 characters.
 *
 * ---
 *
 * Future person, this function has been re-exported by:
 *  - apps/insights/involuntary-churn
 *  - apps/insights/voluntary-churn
 * If you extract this out of webapp into a standalone util please inform the FE teams, so they are aware:
 *  - `@hotjar/re-engagement`
 */
export function useEffectOnceWithDependencies(
  effect: EffectCallback,
  dependencies: DependencyList,
  condition?: PredicateFunction,
): void {
  const called = useRef(false);

  useEffect(() => {
    // Stop if we already called `effect` once
    if (called.current) {
      return noop;
    }

    // Stop if we have conditions defined but those conditions are not met
    if (condition && !condition()) {
      return noop;
    }

    // Execute the effect
    const cleanupFunction = effect();

    // Mark the effect as already run
    called.current = true;

    // Stop if we don't have a cleanup function from the effect
    if (!cleanupFunction || typeof cleanupFunction !== 'function') {
      return noop;
    }

    // Return the cleanup function for `useEffect` to execute
    return cleanupFunction;
  }, [effect, dependencies, condition]);
}
