import { createContext, useContext, useState, useEffect } from 'react';

import { Route } from "../api/directions.type";
import { LongLat } from '../api/geoLocation.type';
import { useDirections } from '../api/useDirections';

export interface LocationDetails {
    coordinates: LongLat;
    text: string;
    placeName: string;
}

export interface JourneyDetails {
    from?: LocationDetails;
    to?: LocationDetails;
}

interface Props {
    routes: Route[],
    selectedRoute: Route | null,
    setSelectedRoute: (route: Route) => void,
    journey: JourneyDetails | null,
    setJourney: (route: JourneyDetails) => void,
}

const RouteContext = createContext<Props>({
    routes: [],
    selectedRoute: null,
    setSelectedRoute: () => { },
    journey: null,
    setJourney: () => { },
});

export function RouteProvider({ children }: { children: React.ReactNode }) {
    const [journey, setJourney] = useState<JourneyDetails | null>(null);
    const directions = useDirections(journey);
    const [selectedRoute, setSelectedRoute] = useState<Route | null>(null);

    useEffect(() => {
        setSelectedRoute(directions?.routes?.[0] ?? null);
    }, [directions]);

    return <RouteContext.Provider value={{
        routes: directions?.routes ?? [],
        selectedRoute,
        setSelectedRoute,
        journey,
        setJourney,
    }}>{children}</RouteContext.Provider>;
}

export function useRoutes() {
    const routeContext = useContext(RouteContext);

    return routeContext;
}

export function useJourney() {
    const { journey, setJourney } = useContext(RouteContext);

    return {
        journey,
        setJourney,
        swapToAndFrom: () => {
            if (!journey?.from && !journey?.to) {
                console.error('Unable to swap to and from location as there are none.');
                return;
            }

            setJourney({
                to: journey.from,
                from: journey.to,
            });
        }
    };
}