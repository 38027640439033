import "./App.css";
import { Journey } from "./journey/Journey";
import { Weather } from "./weather/Weather";
import { Map } from "./map/Map";
import { RouteProvider } from "./state/RouteContext";
import { MapInfoProvider } from "./state/MapInfoProvider";
import { WeatherProvider } from "./state/WeatherProvider";
import { CurrentLocationProvider } from "./state/CurrenLocationProvider";
import { SeoContent } from "./seo/SeoContent";
import { useInitMixpanel } from "./tracking/useInitMixpanel";

function App() {
  useInitMixpanel();

  return (
    <RouteProvider>
      <MapInfoProvider>
        <CurrentLocationProvider>
          <WeatherProvider>
            <div className="container">
              <Journey />
              <Map />
              <Weather />
            </div>
            <SeoContent />
          </WeatherProvider>
        </CurrentLocationProvider>
      </MapInfoProvider>
    </RouteProvider>
  );
}

export default App;
