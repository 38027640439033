import { createContext, useContext } from 'react';
import { useWeatherApi } from '../api/useWeatherApi';
import { WeatherInfo } from '../api/weatherInfo.type';
import { useJourney } from './RouteContext';

interface Props {
    weatherInfoFrom: WeatherInfo | null;
    weatherInfoTo: WeatherInfo | null;
}

const WeatherContext = createContext<Props>({ weatherInfoFrom: null, weatherInfoTo: null, });

export function WeatherProvider({ children }: { children: React.ReactNode }) {
    const { journey } = useJourney();
    // TODO make this 1 api call??
    const weatherInfoFrom = useWeatherApi({ coordinates: journey?.from?.coordinates });
    const weatherInfoTo = useWeatherApi({ coordinates: journey?.to?.coordinates });

    return <WeatherContext.Provider value={{
        weatherInfoFrom,
        weatherInfoTo,
    }}>{children}</WeatherContext.Provider>;
}

export function useWeather() {
    return useContext(WeatherContext);
}