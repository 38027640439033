export function HeadWindTips() {
    return (
        <>
            <h2>Hoe fiets je effectief tegen de wind in</h2>
            <p>
                Tegenwind hebben is natuurlijk echt geen pretje... Met deze tips kom jij met zo min mogelijk
                moeite toch nog snel vooruit!
            </p>

            <h3>Maak jezelf klein</h3>
            <p>
                Zorg dat je jezelf zo klein mogelijk maakt, zo heeft de tegenwind minder oppervlakte om jou tegen
                werken. Je kan op deze manier al snel 3 kilometer per uur sneller fietsen.
            </p>

            <h3>Imiteer de wielrenners van de tour de France!</h3>
            <p>Je ziet vaak in de tour de France dat ze in groepjes achter elkaar fietsen. Deze proffesionals doen dit
                natuurlijk niet zonder rede. Als je achter iemand anders fietst kost dit namelijk al snel zo'n 30% minder
                energie.</p>

            <h3>Zorg voor zo min mogelijk weerstand</h3>
            <p>
                Dit is eigenlijk ook een samenvatting van de vorige tips! Doe er alles aan om
                zo min mogelijk weerstand te hebben. Denk bijvoorbeeld ook aan je banden oppompen en
                strakke kleding aantrekken die niet heen en weer wappert in de wind.
            </p>
        </>
    )
}