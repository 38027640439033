import { useEffect, useState } from "react";
import { angleAverage, getWindDirectionInfo } from "./utils";
import "./Weather.css";
import { LocationWeather } from "./components/LocationWeather";
import { WindDirection } from "./components/WindDirection";
import { Panel } from "./components/Panel";
import { WiWindDeg } from "react-icons/wi";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { useRoutes } from "../state/RouteContext";
import { useMapRotation } from "../state/MapInfoProvider";
import { useWeather } from "../state/WeatherProvider";
import { Add } from "../adds/Add";

export enum CycleWindDirection {
  MEEWIND = "meewind",
  TEGENWIND = "tegenwind",
  ZIJWIND = "zijwind",
}

export interface WindDirectionsInfo {
  [CycleWindDirection.TEGENWIND]: WindDirectionInfo;
  [CycleWindDirection.MEEWIND]: WindDirectionInfo;
  [CycleWindDirection.ZIJWIND]: WindDirectionInfo;
}

interface WindDirectionInfo {
  /** in meter */
  distance: number;
  /** in seconds */
  duration: number;
  /** based on distance */
  percentage?: string;
}

export function Weather() {
  const { selectedRoute, journey } = useRoutes();
  const { weatherInfoFrom, weatherInfoTo } = useWeather();
  const [windDirectionsInfo, setWindDirectionInfo] = useState<
    WindDirectionsInfo | undefined
  >();
  const isMobile = useMediaQuery("(max-width: 992px)");
  const { rotation: mapRotation } = useMapRotation();

  useEffect(() => {
    if (weatherInfoTo && weatherInfoFrom && selectedRoute) {
      setWindDirectionInfo(
        getWindDirectionInfo(selectedRoute, weatherInfoFrom, weatherInfoTo)
      );
    } else {
      setWindDirectionInfo(undefined);
    }
  }, [selectedRoute, weatherInfoTo, weatherInfoFrom]);

  if (!journey) {
    return null;
  }

  return (
    <>
      {weatherInfoFrom && weatherInfoTo && (
        <div className="wind-direction">
          <div
            style={{
              transform: `rotate(${
                angleAverage(weatherInfoFrom.wind.deg, weatherInfoTo.wind.deg) +
                180 +
                mapRotation
              }deg)`,
            }}
          >
            <WiWindDeg color="#031B4E" size={isMobile ? 34 : 64} />
          </div>
          <span className="wind-direction__text">
            gemiddelde <br /> windrichting
          </span>
        </div>
      )}

      {(journey.to || journey.from) && (
        <Panel>
          {windDirectionsInfo && (
            <WindDirection windDirectionsInfo={windDirectionsInfo} />
          )}

          <Add weatherInfo={weatherInfoFrom} distance={selectedRoute?.distance} placeName={journey.to?.placeName} />

          <div className="location-weather__container">
            {weatherInfoFrom && journey.from && (
              <LocationWeather
                location={journey.from.text}
                weatherInfo={weatherInfoFrom}
              />
            )}

            {weatherInfoTo && journey.to && (
              <LocationWeather
                location={journey.to.text}
                weatherInfo={weatherInfoTo}
              />
            )}
          </div>
        </Panel>
      )}
    </>
  );
}
