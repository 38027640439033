export function ClimateChange() {
    return (
        <>
            <h2>De wind en klimaatverandering</h2>
            <p>
                De wind heeft op twee manieren te maken met klimaat verandering. We kunnen
                de wind namelijk gebruiken als een (gedeeltelijke) oplossing van klimaatverandering
                en de aan de andere kant heeft klimaatverandering ook weer effect op de wind.
            </p>

            <h3>Windmolens</h3>
            <p>
                Windmolens zijn een van de ingenieuze uitvindingen tegen klimaatveranderingen. Doordat
                de wind de wieken van de windmolen bewegen kunnen wij deze kinetische energie omzetten
                in electriciteit. Echte groene stroom dus!
            </p>

            <h3>De verandering van de wind</h3>
            <p>
                Een gevolg van klimaatverandering is dat er boven oceanen meer storingen ontstaan, die vervolgens
                naar land trekken. Dit breng meer wind met zich mee en ook minder goed voorspelbare wind.
            </p>
        </>
    )
}