import { AmazonLink } from "./amazon-links";
import styled from "@emotion/styled";
import reactStringReplace from "react-string-replace";
import { trackEvent } from "../tracking/utils";
import { useEffectOnceWithDependencies } from "../hooks/useEffectOnceWithDependencies";

const S = {
  Link: styled.a`
    color: #fff;
  `,
};

interface Props {
  link: AmazonLink;
}

export const AddLink = ({ link }: Props) => {
  useEffectOnceWithDependencies(() => {
    trackEvent("Add Link Seen", { item: link.item });
  }, []);

  const openPage = (url: string) => {
    trackEvent("Add Link Clicked", { item: link.item });
    window.open(url, "_blank");
  };

  return (
    <p>
      {reactStringReplace(link.text, /{{(.*?)}}/, (match) => (
        <S.Link
          onMouseDown={() => openPage(link.link)}
          target="_blank"
          href={link.link}
        >
          {match}
        </S.Link>
      ))}
    </p>
  );
};
