import { useEffect, useState } from "react";
import { useDebounce } from '../hooks/useDebounce';
import { LocationDetails } from "../state/RouteContext";
import { GeoCodingResponse } from './geoLocation.type';

type ReturnType = [
    GeoCodingResponse | undefined,
    LocationDetails | undefined,
    React.Dispatch<React.SetStateAction<LocationDetails | undefined>>
];

export const useSearchLocation = (searchterm?: string): ReturnType => {
    const [geoLocations, setGeoLocations] = useState<GeoCodingResponse | undefined>();
    const [selectedGeoLocation, setSelectedGeoLocation] = useState<LocationDetails | undefined>();
    const debouncedSearchterm = useDebounce<string | undefined>(searchterm);

    const getGeoLocation = async (term: string) => {
        const response = await
            fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${term}.json?access_token=${process.env.REACT_APP_MAP_API_KEY}&language=nl&country=NL`);
        const newGeoLocation = await response.json();
        // TODO error handling 
        setGeoLocations(newGeoLocation);
    }

    useEffect(() => {
        if (debouncedSearchterm === selectedGeoLocation?.text) return;

        if (debouncedSearchterm) {
            getGeoLocation(debouncedSearchterm);
        } else {
            setGeoLocations(undefined);
        }
    }, [debouncedSearchterm]);

    return [geoLocations, selectedGeoLocation, setSelectedGeoLocation];
}