import { useEffect, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import ReactMapboxGl from 'react-mapbox-gl';
import { FitBounds } from 'react-mapbox-gl/lib/map';

import { Marker } from './Marker';
import { Route } from './Route';
import { useMediaQuery } from '../hooks/useMediaQuery';
import { useJourney } from '../state/RouteContext';
import { useMapRotation } from '../state/MapInfoProvider';
import { CurrentPosition } from './CurrentPosition';
import { LongLat } from '../api/geoLocation.type';

const MapGl = ReactMapboxGl({
    accessToken: process.env.REACT_APP_MAP_API_KEY ?? '',
    trackResize: false,
});

export function Map() {
    const [fitBounds, setFitBounds] = useState<FitBounds>();
    const isTablet = useMediaQuery('(max-width: 1440px)');
    const isMobile = useMediaQuery('(max-width: 992px)');
    const { journey } = useJourney();
    const { setRotation } = useMapRotation();

    useEffect(() => {
        if (journey?.to?.coordinates && journey?.from?.coordinates) {
            setFitBounds([journey?.to.coordinates, journey?.from.coordinates]);
        } else {
            setFitBounds(undefined);
        }
    }, [journey]);


    const getContainerStyle = (): {} => {
        if (isMobile) {
            return {
                width: '100vw',
                height: 'calc(100% - 175px)',
                minHeight: 'calc(100% - 175px)',
                position: 'relative',
            }
        }

        return {
            width: '100vw',
            height: '100%',
        }
    }

    const getBoundsPadding = (): {} => {
        if (isMobile) {
            return {
                padding: {
                    left: 24,
                    top: 66,
                    right: 24,
                    bottom: 145,
                },
            }
        } else if (isTablet) {
            return {
                padding: {
                    left: 364,
                    top: 24,
                    right: 364,
                    bottom: 24,
                },
            }
        }

        return {
            padding: {
                left: 500,
                top: 46,
                right: 520,
                bottom: 20,
            },
        }
    }

    function getCenter(): LongLat | undefined {
        if (journey?.to && journey.from) return;
        if (journey?.from) return journey.from.coordinates;
        if (journey?.to) return journey.to.coordinates;
        return [5.291266, 52.132633];
    }

    return (
        <MapGl
            style="mapbox://styles/elseee/ckxq3srj94u1r15qop6ez2vav"
            containerStyle={getContainerStyle()}
            center={getCenter()}
            zoom={journey?.to || journey?.from ? undefined : [7]}
            fitBounds={fitBounds}
            fitBoundsOptions={getBoundsPadding()}
            onRotateEnd={(event) => {
                // convert radians to degrees
                setRotation(event.transform.angle * (180 / Math.PI));
            }}
        >
            {/* From marker */}
            <Marker coordinates={journey?.from?.coordinates} />
            {/* To marker */}
            <Marker isDestination={true} coordinates={journey?.to?.coordinates} />

            <Route />

            <CurrentPosition />
        </MapGl>
    );
}
