import { Marker as MarkerGl } from 'react-mapbox-gl';
import { HiLocationMarker } from 'react-icons/hi'
import { BsRecordCircleFill } from 'react-icons/bs';

import { LongLat } from '../api/geoLocation.type';

export function Marker({ coordinates, isDestination }: { coordinates?: LongLat, isDestination?: boolean }) {
    if (!coordinates) return null;

    return (
        <MarkerGl
            coordinates={coordinates}
            anchor="center"
        >
            <div style={{ position: 'static' }}>
                {isDestination ?
                    <HiLocationMarker size={22} color="#0067FF" />
                    : <BsRecordCircleFill size={14} color="#626262" />}
            </div>
        </MarkerGl>
    )
}