import { Route } from '../api/directions.type';
import { CycleWindDirection, WindDirectionsInfo, } from './Weather';
import { WeatherInfo } from '../api/weatherInfo.type';
const compassBearing = require("compass-bearing");

export function getBeaufortWindForce(speed: number) {
    if (speed < 0.3) return 0;
    else if (speed < 1.6) return 1;
    else if (speed < 3.4) return 2;
    else if (speed < 5.5) return 3;
    else if (speed < 8.0) return 4;
    else if (speed < 10.8) return 5;
    else if (speed < 13.9) return 6;
    else if (speed < 17.2) return 7
    else if (speed < 20.8) return 8;
    else if (speed < 24.5) return 9;
    else if (speed < 28.5) return 10;
    else if (speed < 32.6) return 11;
    else return 12;
}

export type WindDirection = 'N' | 'NNO' | 'NO' | 'ONO' | 'O' | 'OZO' | 'ZO' | 'ZZO' | 'Z' | 'ZZW' | 'ZW' | 'WZW' | 'W' | 'WNW' | 'NW' | 'NNW';

export function getWindDirection(degrees: number): WindDirection | undefined {
    if (348.75 < degrees && degrees < 11.25) return 'N';
    if (11.25 < degrees && degrees < 33.75) return 'NNO';
    if (33.75 < degrees && degrees < 56.25) return 'NO';
    if (56.25 < degrees && degrees < 78.75) return 'ONO';
    if (78.75 < degrees && degrees < 101.25) return 'O';
    if (101.25 < degrees && degrees < 123.75) return 'OZO';
    if (123.75 < degrees && degrees < 146.25) return 'ZO';
    if (146.25 < degrees && degrees < 168.75) return 'ZZO';
    if (168.75 < degrees && degrees < 191.25) return 'Z';
    if (191.25 < degrees && degrees < 213.75) return 'ZZW';
    if (213.75 < degrees && degrees < 236.25) return 'ZW';
    if (236.25 < degrees && degrees < 258.75) return 'WZW';
    if (258.75 < degrees && degrees < 281.25) return 'W';
    if (281.25 < degrees && degrees < 303.75) return 'WNW';
    if (303.75 < degrees && degrees < 326.25) return 'NW';
    if (326.25 < degrees && degrees < 348.75) return 'NNW';
}

function getPercentage(amount: number, total: number): string {
    return `${(amount / total * 100).toFixed()}%`
}

export function getWindDirectionInfo(route: Route, weatherInfoFrom: WeatherInfo, weatherInfoTo: WeatherInfo): WindDirectionsInfo {
    const windDirectionInfo: WindDirectionsInfo = {
        tegenwind: { distance: 0, duration: 0 },
        meewind: { distance: 0, duration: 0 },
        zijwind: { distance: 0, duration: 0 },
    }

    route.legs.forEach((leg) => {
        let distanceTraveled = 0;

        leg.steps.forEach((step) => {
            const averageOpositeWindDirection = distanceTraveled >= route.distance / 2 ?
                weatherInfoTo.wind.deg
                : weatherInfoFrom.wind.deg;
            const averageWindDirection = (averageOpositeWindDirection + 180) % 360;
            distanceTraveled += step.distance;

            const lastIndex = step.geometry.coordinates.length - 1;
            let averageDirection = compassBearing(step.geometry.coordinates[0], step.geometry.coordinates[lastIndex]);

            let windDirection: CycleWindDirection = CycleWindDirection.ZIJWIND;
            if (averageDirection > averageWindDirection - 35 && averageWindDirection + 35 > averageDirection) {
                windDirection = CycleWindDirection.MEEWIND;
            } else if (averageDirection > averageOpositeWindDirection - 35 && averageOpositeWindDirection + 35 > averageDirection) {
                windDirection = CycleWindDirection.TEGENWIND;
            }

            windDirectionInfo[windDirection].distance += step.distance;
            windDirectionInfo[windDirection].duration += step.duration;
        })
    });
    windDirectionInfo.meewind.percentage = getPercentage(windDirectionInfo.meewind.distance, route.distance);
    windDirectionInfo.tegenwind.percentage = getPercentage(windDirectionInfo.tegenwind.distance, route.distance);
    windDirectionInfo.zijwind.percentage = getPercentage(windDirectionInfo.zijwind.distance, route.distance);

    return windDirectionInfo;
}

export function angleAverage(before: number, after: number) {
    const differance = (before - after + 180) % 360 - 180;
    const diff = differance < -180 ? differance + 360 : differance;

    if (before > after) {
        return before - (diff / 2);
    } else {
        return after - (diff / 2);
    }
}
