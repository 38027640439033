import { Link } from "react-router-dom";

export function WindOrigin() {
    return (
        <>
            <h2>Hoe onstaat wind?</h2>
            <p>
                Waar het allemaal begint is onze zon en het feit dat onze aarde bolvormig is. Rond de evenaar komt
                er hierdoor een grote hoeveelheid zonnestraling, waardoor dit gedeelte sterk opwarmt. Zoals je misschien wel
                weet stijgt warme lucht omhoog. Dit is ook precies wat er rond de evenaar gebeurt. Vervolgens ontstaat er hierdoor
                weer een tekort aan lucht op het aardoppervlak. Dit tekort wordt dan weer aangevuld door lucht die zich verplaatst
                uit omliggende gebieden, en zo ontstaat de wind.
            </p>

            <p>Als je meer informatie wilt over hoe de wind draait kan je dat <Link to={'/waarom-draait-de-wind'}>hier</Link> vinden.</p>
        </>
    )
}