import { createContext, useContext, useState } from 'react';

interface Props {
    rotation: number,
    setRotation: (rotation: number) => void
}

const MapInfoContext = createContext<Props>({ rotation: 0, setRotation: () => { } });

export function MapInfoProvider({ children }: { children: React.ReactNode }) {
    const [rotation, setRotation] = useState<number>(0);

    return <MapInfoContext.Provider value={{
        rotation,
        setRotation,
    }}>{children}</MapInfoContext.Provider>;
}

export function useMapRotation() {
    return useContext(MapInfoContext);
}