import { BsCloudRain, BsSnow, BsWind } from 'react-icons/bs';
import { FaTemperatureHigh } from 'react-icons/fa'

import './LocationWeather.css';
import { WeatherInfo } from "../../api/weatherInfo.type";
import { getBeaufortWindForce, getWindDirection } from "../utils";

export function LocationWeather({ location, weatherInfo }: { location: string, weatherInfo: WeatherInfo }) {
    return (
        <div className="location-container">
            <h2>{location}</h2>
            <span className="location-weather__item">
                <FaTemperatureHigh />
                <span className="location-weather__value">{weatherInfo.main.temp.toFixed(1)} &#8451;</span>
            </span>
            <span className="location-weather__item">
                <BsWind />
                <span className="location-weather__value">
                    {getWindDirection(weatherInfo.wind.deg)}
                    <span className="location-weather__wind-force">{getBeaufortWindForce(weatherInfo.wind.speed)}</span>
                </span>
            </span>
            <span className="location-weather__item">
                <BsCloudRain />
                <span className="location-weather__value">{weatherInfo.rain ? `${weatherInfo.rain["1h"]} mm` : 'Geen regen'}</span>
            </span>
            {weatherInfo.snow && <span className="location-weather__item">
                <BsSnow />
                <span className="location-weather__value">{weatherInfo.snow["1h"]} mm</span>
            </span>}
        </div>
    )
}