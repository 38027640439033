export interface AmazonLink {
  item: string;
  link: string;
  text: string;
}

type Circumstance =
  | "rain"
  | "snow"
  | "dark"
  | "windy"
  | "cityLocation"
  | "longDistance"
  | 'cold';

type AmazonLinks = Record<Circumstance, AmazonLink>;

export const amazonLinks: AmazonLinks = {
  rain: {
    item: "rain coat",
    link: "https://www.amazon.nl/Azeekoom-Regenjas-Waterdichte-Multifunctionele-Picknick/dp/B09P1G2R67/ref=sr_1_13?__mk_nl_NL=%25C3%2585M%25C3%2585%25C5%25BD%25C3%2595%25C3%2591&amp;crid=3P4EIDWFOOFUO&amp;keywords=fiets%252Bponcho&amp;qid=1705500088&amp;sprefix=fietsponcho%252Caps%252C79&amp;sr=8-13&amp;th=1&_encoding=UTF8&tag=hebiktegenwin-21&linkCode=ur2&linkId=2ec64a91f49adf0fac21b5bd4f1d96fe&camp=247&creative=1211",
    text: "☔️ Het lijkt erop dat het gaat regenen, koop {{hier een regenjas.}}",
  },
  snow: {
    item: "gloves",
    link: "https://www.amazon.nl/Boildeg-Fietshandschoenen-Mountainbike-Ademend-Touchscreen/dp/B08CXL3BTZ/ref=sr_1_6?__mk_nl_NL=%25C3%2585M%25C3%2585%25C5%25BD%25C3%2595%25C3%2591&amp;crid=10XOD9BT40CTG&amp;keywords=fietshandschoenen&amp;qid=1705500908&amp;sprefix=fiets%252Bhandschoenen%252Caps%252C83&amp;sr=8-6&amp;th=1&_encoding=UTF8&tag=hebiktegenwin-21&linkCode=ur2&linkId=087ad0b418380cfc6de7bc1b461f94b5&camp=247&creative=1211",
    text: "☃️ Het lijkt erop dat het gaat sneeuwen, koop {{hier handschoenen.}}",
  },
  cold: {
    item: "gloves",
    link: "https://www.amazon.nl/Boildeg-Fietshandschoenen-Mountainbike-Ademend-Touchscreen/dp/B08CXL3BTZ/ref=sr_1_6?__mk_nl_NL=%25C3%2585M%25C3%2585%25C5%25BD%25C3%2595%25C3%2591&amp;crid=10XOD9BT40CTG&amp;keywords=fietshandschoenen&amp;qid=1705500908&amp;sprefix=fiets%252Bhandschoenen%252Caps%252C83&amp;sr=8-6&amp;th=1&_encoding=UTF8&tag=hebiktegenwin-21&linkCode=ur2&linkId=087ad0b418380cfc6de7bc1b461f94b5&camp=247&creative=1211",
    text: "🥶 Het lijkt erop dat het koud wordt, koop {{hier handschoenen.}}",
  },
  windy: {
    item: "electrical bike",
    link: "https://www.amazon.nl/elektrische-koppelsensor-versnellingen-kleurenscherm-APP-bediening/dp/B0BZ8Z7MF3/ref=sr_1_1_sspa?__mk_nl_NL=%25C3%2585M%25C3%2585%25C5%25BD%25C3%2595%25C3%2591&amp;crid=3F54TBI5J4Y6L&amp;keywords=elektrische%252Bfiets&amp;qid=1705500264&amp;sprefix=electrische%252Bfiets%252Caps%252C81&amp;sr=8-1-spons&amp;sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&amp;th=1&_encoding=UTF8&tag=hebiktegenwin-21&linkCode=ur2&linkId=c1e241a27cf8ffd42e6019674362eb30&camp=247&creative=1211",
    text: "🌬 Het gaat flink waaien, als eens aan {{een electrische fiets}} gedacht?",
  },
  dark: {
    item: "lights",
    link: "https://www.amazon.nl/CIRYCASE-Fietsverlichting-Fietslicht-Nachtritten-Verlichtingsmodi/dp/B0B3SX6QPQ/ref=sr_1_6?__mk_nl_NL=%25C3%2585M%25C3%2585%25C5%25BD%25C3%2595%25C3%2591&amp;crid=UKC6G8B0T7LF&amp;keywords=fietslamp&amp;qid=1705500159&amp;sprefix=fiets+lamp%252Caps%252C83&amp;sr=8-6&_encoding=UTF8&tag=hebiktegenwin-21&linkCode=ur2&linkId=71de8c134728f2ff245951acc1897e2a&camp=247&creative=1211",
    text: "🌑 Het is al donker, zorg dat je met {{fiets verlichting}} op stap gaat!",
  },
  longDistance: {
    item: "pump",
    link: "https://www.amazon.nl/Woowind-LP1-Elektrische-Bandenpomp-Compressor/dp/B09MYNX9BV/ref=sr_1_1_sspa?crid=4LQ0XEEOK4AE&amp;keywords=fietspomp&amp;qid=1705500183&amp;sprefix=fiets%252Caps%252C89&amp;sr=8-1-spons&amp;sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&amp;psc=1&_encoding=UTF8&tag=hebiktegenwin-21&linkCode=ur2&linkId=52d63f60f834e80cbbd5b5c75085cda9&camp=247&creative=1211",
    text: '🗺 Je hebt een flinke tocht voor de boeg! Ben op alles voorbereid met {{deze fietspomp.}}'
  },
  cityLocation: {
    item: "lock",
    link: "https://www.amazon.nl/SPGOOD-waterdichte-anti-diefstal-veiligheid-kettingslot/dp/B0BCW8F3ZD/ref=sr_1_5?crid=1D8ZMN0CGAHRG&amp;keywords=fietsslot&amp;qid=1705500190&amp;sprefix=fietsslot%252Caps%252C102&amp;sr=8-5&_encoding=UTF8&tag=hebiktegenwin-21&linkCode=ur2&linkId=5533df19c7c4fa9a47e6c2ff7f7922b0&camp=247&creative=1211",
    text: "🦹‍♂️ Zorg dat je je fiets veilig achterlaat met {{dit slot.}}",
  },
/**
 * ideas:
 * - sunglasses when sunny
 * - 
 */
};

