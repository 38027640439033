import { BiCurrentLocation } from 'react-icons/bi';
import './AutoComplete.css';
import { Location, LongLat } from '../../api/geoLocation.type';
import { useCurrentLocation } from '../../state/CurrenLocationProvider';
import { useJourney } from '../../state/RouteContext';

interface Props {
    values?: Location[];
    onClick: (value: Location | LongLat) => void;
    showCurrentLocation: boolean;
}

export const AutoComplete = ({ values, onClick, showCurrentLocation }: Props) => {
    const location = useCurrentLocation();

    return (
        <div className="auto-complete__container">
            {location && showCurrentLocation &&
                <button
                    className="item"
                    onMouseDown={() => onClick([location?.coords.longitude, location?.coords.latitude])}
                >
                    <BiCurrentLocation />
                    <span style={{ paddingLeft: 6 }}> Gebruik huidige locatie</span>
                </button>
            }
            {values?.map(value =>
                <button
                    className="item"
                    key={value.id}
                    onMouseDown={() => onClick(value)}
                >{value.place_name}</button>
            )}
        </div>
    )
};