import { createContext, useContext, useEffect, useState } from 'react';
import { useDebounce } from '../hooks/useDebounce';

const CurrenLocationContext = createContext<GeolocationPosition | null | undefined>(null);

export function CurrentLocationProvider({ children }: { children: React.ReactNode }) {
    const [location, setLocation] = useState<GeolocationPosition | null>(null);
    const debouncedLocation = useDebounce(location);

    useEffect(() => {
        let watchId: number;

        if (navigator.geolocation) {
            watchId = navigator.geolocation.watchPosition((location) => {
                setLocation(location);
            });
        }

        return () => {
            if (navigator.geolocation) {
                navigator.geolocation.clearWatch(watchId);
            }
        }
    }, []);

    return <CurrenLocationContext.Provider value={debouncedLocation}>{children}</CurrenLocationContext.Provider>;
}

export function useCurrentLocation() {
    return useContext(CurrenLocationContext);
}