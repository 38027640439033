import { useWeatherApi } from "../../api/useWeatherApi";
import { getBeaufortWindForce, getWindDirection } from "../../weather/utils";

export function Main() {
    const weatherNl = useWeatherApi({ coordinates: [5.291266, 52.132633] });

    return (
        <>
            <h2>De windrichting (vandaag in Nederland)</h2>
            <p>
                De windrichting in Nederland verschilt erg per seizoen, maand en dag. Zo waait het in
                de zomermaanden gemiddeld tussen 10 en 13 km/uur, oftewel windkracht 2-3 op
                <a href="https://www.knmi.nl/kennis-en-datacentrum/uitleg/windschaal-van-beaufort"> de Beaufort schaal</a>.
                In de wintermaanden ligt de gemiddelde windsnelheid tussen de 13 tot 16 km/uur, oftewel windrkacht 3.
            </p>
            {
                weatherNl &&
                <p>
                    Vandaag waait het in Nederland iets {getBeaufortWindForce(weatherNl.wind.speed) < 3 ? 'minder hard ' : 'harder '}
                    dan normaal, met een windrkacht van {getBeaufortWindForce(weatherNl.wind.speed)}Bft. De windrichting is {getWindDirection(weatherNl.wind.deg)}.
                    Verder is het {weatherNl.main.temp.toFixed(1)}&#176;C en wordt er {weatherNl.rain ? `${weatherNl.rain['1h']}mm` : 'geen'} regen verwacht komend uur.
                </p>
            }
        </>
    )
}