import { Link } from "react-router-dom";

export function WindShifts() {
    return (
        <>
            <h2>Waarom draait de wind?</h2>
            <p>
                In basis draait de wind omdat er een stroming van de lucht ontstaat van
                hogedrukgebieden naar lage drukgebieden. <Link to={'/waarom-draait-de-wind'}>Het verschil van luchtdruk is het gevolg van
                    de opwarming en afkoeling van de zon.</Link>
            </p>

            <p>
                De lucht beweegt zich niet direct van hoge naar lage druk, dit komt door de draaiing
                van de aarde die beweegt. Daardoor buigt de wind zicht op het noordelijke halfrond naar
                rechts af. De lucht beweegt zich in een hoogdrukgebied met de klok mee en in een laagdrukgebied
                tegen de klok in. Omdat wij op het aardoppervlakte te maken hebben met wrijving, zal de wind afremmen en minder
                worden afgebogen.
            </p>

            <p>
                Ook zal door de draaiing van de aarde de aarde ongelijk verwarmd worden. Dit zorg ervoor dat de
                hoge en lage drukgebieden verplaatsen en de wind dus draait.
            </p>
        </>
    )
}