import { WeatherInfo } from './weatherInfo.type';
import { LongLat } from './geoLocation.type';
import { useApi } from './useApi';

const API_KEY = process.env.REACT_APP_WEATHER_API_KEY;

export const useWeatherApi = ({ coordinates }: { coordinates?: LongLat }): WeatherInfo | null => {
    const weatherInfo = useApi<WeatherInfo>(coordinates ?
        `https://api.openweathermap.org/data/2.5/weather?lat=${coordinates[1]}&lon=${coordinates[0]}&units=metric&&lang=nl&appid=${API_KEY}`
        : null
    );

    return weatherInfo;
}