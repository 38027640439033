import { useEffect, useState } from "react"

export const useApi = <T>(url: string | null): T | null => {
    const [response, setResponse] = useState<T | null>(null);

    const getApiResponse = async (filledUrl: string) => {
        const response = await fetch(filledUrl)
        const responseValue = await response.json();
        // TODO error handling 
        setResponse(responseValue);
    }

    useEffect(() => {
        if (url) {
            getApiResponse(url);
        }
    }, [url]);

    return response;
}