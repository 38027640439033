import './WindDirection.css';
import { WindDirectionsInfo } from '../Weather';

export function WindDirection({ windDirectionsInfo }: { windDirectionsInfo: WindDirectionsInfo }) {
    return (
        <div className="wind-info__container">
            {Object.keys(windDirectionsInfo).map((key) => {
                const value = windDirectionsInfo[key as 'meewind'];
                return (
                    <span className="wind-info" key={key}>
                        <span className="wind-info__percentage">{value.percentage}</span> {key}
                    </span>
                );
            })}
        </div>
    );
}