import { Directions } from "./directions.type";
import { useApi } from './useApi';
import { JourneyDetails } from '../state/RouteContext';

export const useDirections = (journey: JourneyDetails | null): Directions | null => {
    const directions = useApi<Directions>(journey?.from && journey.to ?
        `https://api.mapbox.com/directions/v5/mapbox/cycling/${journey.from.coordinates.join(',')};${journey.to.coordinates.join(',')}?steps=true&access_token=${process.env.REACT_APP_MAP_API_KEY}&geometries=geojson&overview=full&alternatives=true`
        : null);

    return directions;
}