import { useEffect, useRef, useState } from "react";

export const useDebounce = <T>(value: T, delay = 500) => {
    const [debouncedValue, setDebouncedValue] = useState<T>();
    const timeout = useRef<ReturnType<typeof setTimeout>>();

    const clearCurrentTimeout = () => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
    }

    useEffect(() => {
        clearCurrentTimeout();
        timeout.current = setTimeout(() => setDebouncedValue(value), delay)

        return () => {
            clearCurrentTimeout();
        }
    }, [value, delay])

    return debouncedValue;
}