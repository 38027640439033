import { BiChevronLeft, BiChevronLeftCircle } from 'react-icons/bi';
import { TiChevronLeftOutline } from 'react-icons/ti';
import {
    Link,
    LinkProps,
    useResolvedPath,
    useMatch,
} from "react-router-dom";
import { useMediaQuery } from '../hooks/useMediaQuery';

import './CustomLink.css';

export function CustomLink({ children, to, ...props }: LinkProps) {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });
    const isMobile = useMediaQuery('(max-width: 992px)');

    return (
        <div>
            <Link
                className={match ? 'custom-link current' : 'custom-link'}
                to={to}
                {...props}
            >
                {match ?
                    <div style={{ display: 'flex', transform: `rotate(${isMobile ? -90 : 0}deg)` }}>
                        <TiChevronLeftOutline />
                    </div>
                    : null
                }
                {children}
            </Link>
        </div>
    );
}
