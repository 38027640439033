import React, { useRef, useState } from "react";
import { MdDragHandle } from "react-icons/md";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useEffect } from 'react';

export function Panel({ children }: { children: React.ReactNode }) {
    const isMobile = useMediaQuery('(max-width: 992px)');
    const [isOpen, setOpen] = useState<boolean>(false);
    const dragStartY = useRef<number>();
    const panel = useRef<HTMLDivElement>(null);
    const [style, setStyle] = useState<any>();

    const handleDragEnd = (e: any, dragEndY: number) => {
        if (isMobile && dragStartY.current) {
            e.stopPropagation();
            setOpen(dragStartY.current > dragEndY);
        }
    }

    useEffect(() => {
        if (!panel.current || !isMobile) return;

        if (isOpen) {
            setStyle({ bottom: 0 });
        } else {
            setStyle({ bottom: 120 - panel.current.getBoundingClientRect().height });
        }
    }, [children, isMobile, isOpen]);

    return (
        <div
            ref={panel}
            draggable={isMobile}
            className="weather-container"
            style={style}
            onTouchStart={(e) => dragStartY.current = e.changedTouches[0].clientY}
            onTouchEnd={(e) => handleDragEnd(e, e.changedTouches[0].clientY)}
            onDragStart={(e) => dragStartY.current = e.clientY}
            onDragEnd={(e) => handleDragEnd(e, e.clientY)}
        >
            {isMobile &&
                <div className="drag-weather" >
                    <MdDragHandle color="#fff" size="24" />
                </div>
            }
            {children}
        </div>

    )
}