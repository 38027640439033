import { Marker } from "react-mapbox-gl";
import { IconContext } from "react-icons/lib";
import { VscCircleLargeFilled } from "react-icons/vsc";
import { IoIosArrowDropupCircle } from 'react-icons/io';

import { useCurrentLocation } from '../state/CurrenLocationProvider';
import './CurrentPosition.css';

export function CurrentPosition() {
    const location = useCurrentLocation();

    if (!location) return null;

    return (
        <Marker
            coordinates={[location.coords.longitude, location.coords.latitude]}
            anchor="center"
        >
            <div className="position-icon" style={{ transform: `rotate(${location.coords.heading ?? 0}deg)` }}>
                <IconContext.Provider value={{ color: '#0067FF', size: '18' }}>
                    {location.coords.heading ? <IoIosArrowDropupCircle /> : <VscCircleLargeFilled />}
                </IconContext.Provider>
            </div>
        </Marker>

    )
}