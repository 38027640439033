import { useState, useEffect } from 'react';
import { BsArrowDownUp } from 'react-icons/bs';
import { CgArrowLongDownC } from 'react-icons/cg'

import './Journey.css';
import { useSearchLocation } from "../api/useSearchLocation";
import { Input } from "./components/Input";
import { FaBicycle } from 'react-icons/fa';
import { useJourney } from '../state/RouteContext';
import { Location, LongLat } from '../api/geoLocation.type';

export function Journey() {
    const [fromValue, setFromValue] = useState<string>('');
    const [geoLocationsFrom, selectedGeoLocationFrom, setSelectedGeoLocationFrom] = useSearchLocation(fromValue);

    const [toValue, setToValue] = useState<string>('');
    const [geoLocationsTo, selectedGeoLocationTo, setSelectedGeoLocationTo] = useSearchLocation(toValue);

    const { journey, setJourney, swapToAndFrom } = useJourney();

    useEffect(() => {
        setJourney({
            to: selectedGeoLocationTo ? {
                coordinates: selectedGeoLocationTo.coordinates,
                text: selectedGeoLocationTo.text,
                placeName: selectedGeoLocationTo.placeName,
            } : undefined,
            from: selectedGeoLocationFrom ? {
                coordinates: selectedGeoLocationFrom.coordinates,
                text: selectedGeoLocationFrom.text,
                placeName: selectedGeoLocationFrom.placeName,

            } : undefined,
        });
    }, [selectedGeoLocationFrom, selectedGeoLocationTo]);

    useEffect(() => {
        if (journey) {
            setFromValue(journey?.from?.placeName ?? '');
            setToValue(journey?.to?.placeName ?? '');
        }
    }, [journey]);

    function handleFromSelect(val: LongLat | Location) {
        if (Array.isArray(val)) {
            setSelectedGeoLocationFrom({
                coordinates: val,
                text: 'Huidige locatie',
                placeName: 'Huidige locatie',
            });
            setFromValue('Huidige locatie');
        } else {
            setSelectedGeoLocationFrom({
                coordinates: val.geometry.coordinates,
                text: val.text,
                placeName: val.place_name,
            });
            setFromValue(val.place_name);
        }
    }

    function handleToSelect(val: LongLat | Location) {
        if (Array.isArray(val)) {
            setSelectedGeoLocationTo({
                coordinates: val,
                text: 'Huidige locatie',
                placeName: 'Huidige locatie',
            });
            setToValue('Huidige locatie');
        } else {
            setSelectedGeoLocationTo({
                coordinates: val.geometry.coordinates,
                text: val.text,
                placeName: val.place_name,
            });
            setToValue(val.place_name);
        }
    }

    return (
        <div className="journey-container">
            <div className="title-container">
                <h1 className="title">Jouw reis</h1>
                <FaBicycle size="26" />
            </div>
            <div className="journey-input-container">
                <CgArrowLongDownC color="#545963" size="74" />
                <div style={{ width: '100%' }}>
                    <Input
                        onChange={val => {
                            setFromValue(val);
                            if (!val) setSelectedGeoLocationFrom(undefined);
                        }}
                        onSelect={handleFromSelect}
                        autoComplete={geoLocationsFrom?.features}
                        placeholder="Kies startpunt"
                        value={fromValue}
                    />
                    <Input
                        onChange={val => {
                            setToValue(val);
                            if (!val) setSelectedGeoLocationTo(undefined);
                        }}
                        onSelect={handleToSelect}
                        autoComplete={geoLocationsTo?.features}
                        placeholder="Kies bestemming"
                        value={toValue}
                    />
                </div>
                <button
                    disabled={!selectedGeoLocationFrom || !selectedGeoLocationTo}
                    className="swap-button unstyled-button"
                    onClick={swapToAndFrom}
                >
                    <BsArrowDownUp size="28" />
                </button>
            </div>
        </div>
    );
}