import './SeoContent.css';
import { Main } from './info-blocks/Main';
import { CustomLink } from './CustomLink';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import { HeadWindTips } from './info-blocks/HeadWindTips';
import { WindOrigin } from './info-blocks/WindOrigin';
import { WindShifts } from './info-blocks/WindShifts';
import { ClimateChange } from './info-blocks/ClimateChage';

export function SeoContent() {

    return (
        <BrowserRouter>
            <div className="seo-container">
                <div className="content-container">
                    <Routes>
                        {
                            navItems.map((item) => (<Route key={item.url} path={item.url} element={item.component} />))
                        }
                    </Routes>
                </div>
                <div className="menu-container">
                    <h2>Handige links</h2>
                    <nav className="links-nav">
                        <ul className="unstyled-list">
                            {
                                navItems.map((item) => (
                                    <li key={item.url} className="nav-item">
                                        <CustomLink to={item.url}>{item.name}</CustomLink>
                                    </li>
                                ))
                            }
                        </ul>
                    </nav>
                </div>
            </div>
        </BrowserRouter>
    );
}

const navItems = [
    {
        url: '/',
        name: 'Wind in Nederland',
        component: <Main />
    },
    {
        url: '/tegenwind-tips',
        name: 'Hoe fiets je effectief tegen de wind in',
        component: <HeadWindTips />
    },
    {
        url: '/waarom-draait-de-wind',
        name: 'Waarom draait de wind soms?',
        component: <WindShifts />
    },
    {
        url: '/klimaat-verandering',
        name: 'De wind en de opwarming van de aarde',
        component: <ClimateChange />
    },
    {
        url: '/onstaan-van-wind',
        name: 'Hoe onstaat wind?',
        component: <WindOrigin />
    }
];
