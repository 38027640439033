import { useState } from 'react';
import { Location, LongLat } from '../../api/geoLocation.type';
import { useCurrentLocation } from '../../state/CurrenLocationProvider';
import { useJourney } from '../../state/RouteContext';

import { AutoComplete } from './AutoComplete';
import './Input.css';

interface Props {
    onChange: (value: string) => void;
    onSelect: (value: Location | LongLat) => void
    autoComplete?: Location[];
    placeholder: string;
    value: string;
}

export const Input = ({ onChange, onSelect, autoComplete, placeholder, value }: Props) => {
    const [isFocused, setIsFocused] = useState(false);
    const { journey } = useJourney();
    const location = useCurrentLocation();

    function showCurrentLocation(): boolean {
        return !!(journey?.from?.text !== 'Huidige locatie' && journey?.to?.text !== 'Huidige locatie' && location);
    }

    const handleChange = (newValue: string) => {
        onChange(newValue);
    }

    const handleSelect = (newValue: Location | LongLat) => {
        onSelect(newValue);
    }

    return (
        <div className="input-container">
            <input
                type="search"
                value={value}
                onChange={(e) => handleChange(e.target.value)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                className={isFocused && (showCurrentLocation() || autoComplete?.length) ? 'with-autocomplete' : ''}
                placeholder={placeholder}
            />
            {
                (isFocused) &&
                <AutoComplete
                    values={autoComplete}
                    onClick={(newValue) => handleSelect(newValue)}
                    showCurrentLocation={showCurrentLocation()}
                />
            }
        </div>
    )
}