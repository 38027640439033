import mixpanel from "mixpanel-browser";

export const isDev = () => location.hostname.includes("localhost");

const trackIfNotOnDev = (trackFn: () => void) => {
  if (!isDev()) {
    trackFn();
  }
};

export const identifyUser = (id: string) =>
  trackIfNotOnDev(() => mixpanel.identify(id));

type EventName = "Page View" | "Route View" | "Add Link Clicked" | "Add Link Seen";

export const trackEvent = (event: EventName, data?: Record<string, unknown>) =>
  trackIfNotOnDev(() => mixpanel.track(event, data));
