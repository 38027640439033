import mixpanel from "mixpanel-browser";
import { useEffectOnceWithDependencies } from "../hooks/useEffectOnceWithDependencies";

export const useInitMixpanel = () => {
  useEffectOnceWithDependencies(() => {
    if (!location.hostname.includes('localhost')) {
      console.log('not it')
      mixpanel.init("5dc0254ba579e27fed21315c551f8543", {
        debug: true,
        track_pageview: true,
        persistence: "localStorage",
      });
    }
  }, []);
};
