import { Feature, Layer } from "react-mapbox-gl";
import { Route as RouteType } from "../api/directions.type";

import { useRoutes } from "../state/RouteContext";
import { LongLat } from "../api/geoLocation.type";
import { useEffect } from "react";
import { trackEvent } from "../tracking/utils";

export function Route() {
  const { routes, journey, selectedRoute, setSelectedRoute } = useRoutes();

  useEffect(() => {
    if (selectedRoute) {
        const { distance, duration,  } = selectedRoute;
        trackEvent("Route View", { distance, duration, from: journey?.from?.placeName, to: journey?.to?.placeName });
    }
  }, [selectedRoute]);

  if (!routes?.length || !journey?.from || !journey.to) return null;

  return (
    <>
      {routes.map((route: RouteType, i: number) => (
        <div key={i}>
          {/* Route line */}
          <Layer
            type="line"
            layout={{
              "line-join": "round",
            }}
            paint={{
              "line-color": route === selectedRoute ? "#0067FF" : "#929497",
              "line-width": 4,
              "line-opacity": route === selectedRoute ? 0.8 : 0.6,
            }}
            onClick={() => setSelectedRoute(route)}
          >
            <Feature coordinates={route.geometry.coordinates} />
          </Layer>

          {/* Wider invisble clickable routeline */}
          <Layer
            type="line"
            layout={{
              "line-join": "round",
            }}
            paint={{
              "line-color": route === selectedRoute ? "#0067FF" : "#929497",
              "line-width": 18,
              "line-opacity": 0.01,
            }}
            onClick={() => setSelectedRoute(route)}
          >
            <Feature coordinates={route.geometry.coordinates} />
          </Layer>

          {/* Icon with distance */}
          {route.geometry?.coordinates?.length && (
            <Layer
              type="symbol"
              layout={{
                "text-field": `${getInKm(route.distance)}`,
                "text-size": 10,
                "text-font": ["Poppins Regular"],
                "text-anchor": "right",
                "icon-image": "label-icon-2", // todo why is icon missing?
                "icon-text-fit": "both",
                "icon-text-fit-padding": [6, 6, 6, 6],
                "icon-allow-overlap": true,
                "icon-ignore-placement": true,
                "text-allow-overlap": true,
                "text-ignore-placement": true,
              }}
              paint={{
                "text-color": "#031B4E",
              }}
              onClick={() => setSelectedRoute(route)}
            >
              <Feature
                coordinates={getMiddleCoordinate(route.geometry.coordinates)}
              />
            </Layer>
          )}
        </div>
      ))}
    </>
  );
}

function getInKm(distanceInMeter: number): string {
  return `${(distanceInMeter / 1000).toFixed(1)} km`;
}

function getTravelDuration(timeInSeconds: number): string {
  // TODO seems to be awfully long?
  const timeInMin = timeInSeconds / 60;

  return timeInMin <= 60
    ? `${(timeInSeconds / 60).toFixed()} min`
    : `${(timeInMin / 60).toFixed()} uur`;
}

function getMiddleCoordinate(coordinates: LongLat[]): LongLat {
  const middleIndex = +(coordinates.length / 2).toFixed();

  return coordinates[middleIndex];
}
